import * as React from 'react';
import Layout, { blueButtonStyle, contentBlockStyle} from '../components/layout';
import exp from '../components/expdata.js';
import { Link } from 'gatsby';

const headerClass = 'text-3xl my-8';

const ResumePage = () => {
  const resumeBlockStyle = "flex flex-wrap mb-px align-middle bg-white dark:bg-black leading-tight p-2 exp-item rounded"
  return (
    <Layout pagePath="/resume">
      <div className={contentBlockStyle + " container mx-auto my-2 px-3"}>
        <div className="mb-6">
          <h1 className="m-0 text-4xl inline-block"> Résumé </h1>
          <Link to="/interactiveresume/" className="inline-block bg-blue-700 hover:bg-blue-800 font-bold px-8 py-3 border rounded-xl text-white float-right">Interactive Version</Link>
        </div>
        <h3 className={headerClass}>Education</h3>
        <div className={resumeBlockStyle}>
          <div className="w-3/4 p-1">
            <div className="text-xl font-bold">University of Virginia</div>
          </div>
          <div className="w-1/4 p-1">Charlottesville, VA</div>
          <div className="w-3/4 p-1">ME Biomedical Engineering</div>
          <div className="w-1/4 p-1">Aug 2018</div>
        </div>
        <div className={resumeBlockStyle}>
          <div className="w-3/4 p-1">
            <div className="text-xl font-bold">University of Central Florida</div>
          </div>
          <div className="w-1/4 p-1">Orlando, FL</div>
          <div className="w-1/2 p-1">BS Computer Engineering</div>
          <div className="w-1/4 p-1">Minor Psychology</div>
          <div className="w-1/4 p-1">May 2014</div>
        </div>
        <h3 className={headerClass}>Skills</h3>
        <div className="flex flex-wrap">
          {exp.skills.map((skill) => (
              <div className="bg-white dark:bg-black py-1 px-4 m-1 rounded" key={skill}>{skill}</div>
          ))}
        </div>
        <h3 className={headerClass}>Libraries / Frameworks</h3>
        <div className="flex flex-wrap">
          {exp.libs.map((lib) => (
              <div className="bg-white dark:bg-black py-1 px-4 m-1 rounded" key={lib}>{lib}</div>
          ))}
        </div>
      
        <h3 className={headerClass}>Employment History</h3>
        {exp.employment.map((emp) => (
            <div className={resumeBlockStyle} key={emp.id}>
              <div className="w-3/4 p-1">
                <div className="text-xl font-bold">{emp.employer}</div>
              </div>
              <div className="w-1/4 p-1">{emp.dateDisplay}</div>
              {emp.positions.map((pos) => (
                  <div className="w-full flex" key={pos.name}>
                    <div className="w-3/4 p-1 pl-8">{pos.name}</div>
                    <div className="w-1/4 p-1">{pos.dateDisplay}</div>
                  </div>
              ))}
            </div>
        ))}

        <h3 className={headerClass}>Project Experience</h3>
        {exp.projects.map((record) => (
        <div className={resumeBlockStyle} key={record.id}>
          <div className="w-2/4 p-1">
            <div className="text-xl font-bold">{record.name}</div>
          </div>
          <div className="w-1/4 p-1">{record.org}</div>
          <div className="w-1/4 p-1">{record.dateDisplay}</div>
          <div className="w-1/4 p-1">
            {record.roles.map((role) => (
              <div className="text-gray-600 dark:text-gray-400" key={role}>{role}</div>
            ))}
          </div>
          <div className="w-3/4 p-1">
            <div className="text-gray-600 dark:text-gray-400 text-sm">{record.skills.join(', ')}</div>
          </div>
          <div className="w-full m-2">{record.description}</div>
          <div className="w-full p-1 text-green-700 dark:text-green-500" v-if="record.awards.length">
            {record.awards.length > 0 && (
              <h4 className="font-bold">Awards</h4>
            )}
            {record.awards.map((award) => (
                <div className="w-full flex flex-wrap mx-2" key={award.name + '-' + award.description}>
                  <div className="flex-1 p-1">{award.year} - {award.name}</div>
                  <div className="flex-1 p-1">{award.description}</div>
                </div>
            ))}
          </div>
        </div>
        ))}
      </div>
    </Layout>
  )
}

export default ResumePage;
